<template>
  <div class="lock">
    <div class="lock__container">
      <div class="lock__label">
        {{ barrier.variety.title }}
      </div>
      <div class="lock__image">
        <img
          v-if="barrier.attachment && !barrier?.attachment?.video"
          :src="barrier.attachment.url"
          alt=""
        >
        <img
          v-if="!barrier.attachment && !barrier?.attachment?.video"
          src="@/assets/image/barrier.png"
          alt=""
        >
        <img
          v-if="barrier.attachment && barrier?.attachment?.video"
          src="@/assets/image/video.png"
          alt=""
        >
      </div>
      <div class="lock__container-name">
        <div class="lock__lock_name">
          <span
            class="lock__order"
            v-if="order"
          >{{  orderId  }}</span>
          {{ barrier?.device?.name }}
        </div>
        <div class="lock__name">
          {{ barrier?.title }}
        </div>
      </div>
      <div class="lock__type">
        {{ barrier?.type?.title }}
      </div>
      <div class="lock__tools">
        <Delete
          v-if="barrierDeleteButton"
          @click="deleteBarrier(orderId - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Delete from '@/assets/svg/close-gray-icon.svg?inline'
export default {
  name: 'BarriersCardHorizontal',
  components: {
    Delete
  },
  props: {
    barrier: {
      type: Object,
      default: () => {
        return {}
      }
    },
    barrierDeleteButton: Boolean,
    deleteBarrier: {
      type: Function,
      default: () => {
        return false
      }
    },
    order: Boolean,
    orderId: [String, Number]
  }

}
</script>

<style lang="scss" scoped>
.lock {
  background: #ffffff;
  border: 1px solid #d2d4d8;
  border-radius: 15px;
  margin-bottom: 10px;

  &__container {
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
    height: 100px;
  }

  &__type {
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    background: #546c48;
    border-radius: 50px;
    padding: 5px 20px;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &__container-name {
    text-align: left;
    padding-left: 10px;
    max-width: 55%;
    overflow: hidden;
  }

  &__label {
    background: #414141;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    width: 80px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  &__order {
    background: #8cb4b1;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 800;
    font-size: 12px;
    border-radius: 90px;
    margin-right: 10px;
  }

  &__image {
    width: 65px;
    img {
      width: 100%;
      max-width: 70px;
      height: 80px;
      object-fit: cover;
    }
  }

  &__lock_name {
    font-size: 18px;
    color: #8c8c8c;
    padding-bottom: 10px;
    display: flex;
  }

  &__name {
    font-size: 18px;
    height: 45px;
    overflow: hidden;
  }

  &__tools {
    position: absolute;
    top: 0;
    right: 0;
    svg {
      cursor: pointer;

      &:hover {
        fill: #8c8c8c;
      }
    }
  }

  &__button-open {
    @include second-button;
    font-size: 14px;
    height: 40px;
    width: 150px;
  }
}
@media (max-width: $break-md) {
  .lock {
    &__name {
      font-size: 14px;
    }
  }
}
</style>
