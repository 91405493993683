
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import BarriersCardHorizontal from '@/components/molecules/card/BarriersCardHorizontal.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'CreateUnitStepTwo',
  data () {
    return {
      dragging: false,
      isLoading: true,
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      building: {},
      unitId: '',
      unitData: {},
      errorUnit: null,
      differentSelection: false,
      orderId: '',
      visitFlowIds: [],
      handoverFlowIds: [],
      postHandoverIds: [],
      postVisitIds: []
    }
  },
  watch: {
    list1: {
      deep: true,
      handler () {
        this.orderId = this.list1.length
        this.visitFlowIds = this.list1.map((obj) => obj.id)
        if (this.errorUnit) {
          this.errorUnit.visit_barriers = null
        }
      },
      immediate: true
    },
    list2: {
      deep: true,
      handler () {
        this.orderId = this.list2.length
        this.handoverFlowIds = this.list2.map((obj) => obj.id)
        if (this.errorUnit) {
          this.errorUnit.handover_barriers = null
        }
      },
      immediate: true
    },
    list3: {
      deep: true,
      handler () {
        this.orderId = this.list3.length
        this.postHandoverIds = this.list3.map((obj) => obj.id)
        if (this.errorUnit) {
          this.errorUnit.post_handover_barriers = null
        }
      },
      immediate: true
    },
    list4: {
      deep: true,
      handler () {
        this.orderId = this.list4.length
        this.postVisitIds = this.list4.map((obj) => obj.id)
        if (this.errorUnit) {
          this.errorUnit.post_visit_barriers = null
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.unitId = this.$route.query.unitId
    this.getUnit()
  },
  components: {
    AlertBlock,
    Draggable: VueDraggableNext,
    BarriersCardHorizontal,
    LottieAnimation: Vue3Lottie
  },
  methods: {
    getUnit () {
      this.$store.dispatch('property/getUnit', this.unitId).then(
        (res) => {
          this.unitData = res.data
          this.isLoading = false
          if (this.unitData.draft) {
            if (this.unitData?.draft?.barriers?.visit !== null) {
              this.list1 = this.unitData?.draft?.barriers?.visit
            }
            if (this.unitData?.draft?.barriers?.handover?.length > 0) {
              this.differentSelection = true
            }
            if (this.unitData?.draft?.barriers?.handover !== null) {
              this.list2 = this.unitData?.draft?.barriers?.handover
            }
            if (this.unitData?.draft?.barriers?.post_handover !== null) {
              this.list3 = this.unitData?.draft?.barriers?.post_handover
            }
            if (this.unitData?.draft?.barriers?.post_visit !== null) {
              this.list4 = this.unitData?.draft?.barriers?.post_visit
            }
          }
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    setPropertiesStepTwo (data) {
      this.unitId = this.$route.query.unitId
      data = {
        unitId: this.unitId,
        visit_barriers: this.visitFlowIds,
        different_order: this.differentSelection,
        handover_barriers: this.handoverFlowIds,
        post_handover_barriers: this.postHandoverIds,
        post_visit_barriers: this.postVisitIds
      }
      this.$store.dispatch('property/setPropertiesStepTwo', data).then(
        () => {
          this.$router.push(`/dashboard/create-unit-step-three?unitId=${this.unitId}`)
        },
        (error) => {
          this.errorUnit = error.response.data.errors
        }
      )
    },
    getBuilding (data) {
      data = this.initPropId
      this.$store.dispatch('property/getBuilding', data).then(
        (res) => {
          console.log(res)
          this.building = res.data
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    goToFirstStep () {
      this.$router.push(`/dashboard/create-unit-step-one?unitId=${this.unitId}`)
    },
    deleteVisitFlowBarrier (index) {
      if (index > -1) {
        this.list1.splice(index, 1)
        this.visitFlowIds.splice(index, 1)
      }
    },
    deletePostVisitBarrier (index) {
      if (index > -1) {
        this.list4.splice(index, 1)
        this.visitPostFlowIds.splice(index, 1)
      }
    },
    deleteHandoverFlowBarrier (index) {
      if (index > -1) {
        this.list2.splice(index, 1)
        this.handoverFlowIds.splice(index, 1)
      }
    },
    deletePostHandoverBarrier (index) {
      if (index > -1) {
        this.list3.splice(index, 1)
        this.postHandoverIds.splice(index, 1)
      }
    }
  }
})
